<template>
  <div class="toolbar">
    <div class="left">
      <button class="bg-white" @click="$emit('loadLive')"><i class="fa-solid fa-file-export mr-1"></i> Load Live Data</button>
      <label class="file bg-white" for="load-file"><i class="fa fa-cloud-upload mr-1"></i> Load from File</label>
      <input id="load-file" type="file" accept="application/json, .json" title="Load File" @change="emitFile">
    </div>
    <div class="right">
      <button class="bg-white" @click="$emit('saveFile')"><i class="fa-solid fa-file-export mr-1"></i> Save To File</button>
    </div>
  </div>
</template>

<script>
export default {
  name: "Toolbar",
  emits: ['saveFile', 'loadFile','loadLive'],
  methods: {
    emitFile: function (e) {
      let t = this
      console.log(e.target.files[0]);
      let file = e.target.files[0]
      if (!file.type || file.type !== 'application/json') {
        console.log('File is not JSON')
      } else {
        const reader = new FileReader();
        reader.addEventListener('load', (event) => {
          let data = JSON.parse(event.target.result);
          if (data) {
            t.$emit('loadFile', data)
          }
        });
        reader.readAsText(file);
      }

    }
  }
}
</script>
