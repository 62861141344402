<template>
  <div class="vehicleSelector">
    <div class="icon"><i class="fa fa-car-side fa-2x"></i></div>
    <div>
      <label>Year</label>
      <button v-for="(y, i) in vData" :key="i" @click="year = i" :class="{'selected': year === i, 'edited': !isEqual(oData[i],vData[i]) }">{{i}}</button>
    </div>
    <div v-show="year">
      <label>Model</label>
      <button v-if="year" v-for="(y, i) in vData[year]" :key="i" @click="model = i" :class="{'selected': model === i, 'edited': !isEqual(oData[year][i],vData[year][i]) }">{{i}}</button>
      <button v-else disabled class="opacity-50">Select A Model Year</button>
    </div>
    <div v-show="model && !Array.isArray(vData[year][model])">
      <label>Type</label>
      <button v-if="model && !Array.isArray(vData[year][model])" v-for="(y, i) in vData[year][model]" :key="i" @click="sub = i" :class="{'selected': sub == i, 'edited': !isEqual(oData[year][model][i],vData[year][model][i]) }">{{i}}</button>
    </div>
  </div>
</template>

<script>
import {isEqual} from "lodash";
export default {
  name: "VehicleSelector",
  props: [
      'vData',
      'oData'
  ],
  emits: ["selectVehicle"],
  data() {
    return {
      'year': 0,
      'model': 0,
      'sub': 0,
      isEqual: isEqual
    }
  },
  methods:{
    emitSelection: function () {
      if (this.year && this.model && !Array.isArray(this.vData[this.year][this.model]) && this.sub) {
        console.log('emit vehicle');
        this.$emit("selectVehicle", this.$data)
      } else if (this.year && this.model && Array.isArray(this.vData[this.year][this.model])) {
        this.sub = 0;
        console.log('emit vehicle');
        this.$emit("selectVehicle", this.$data)
      }
    }
  },
  watch: {
    year(){this.emitSelection()},
    model(){this.emitSelection()},
    sub(){this.emitSelection()}
  }
}
</script>
