<template>
  <div id="main">
      <VehicleSelector :v-data="LiveData ? LiveData : []" :o-data="oLiveData ? oLiveData : []" @select-vehicle="onSelectVehicle" :edit-data="EditData" :start-data="StartData"></VehicleSelector>
      <PackageEditor :edit-data="EditData" :start-data="StartData" :name="EditName"></PackageEditor>
      <Toolbar :edit-data="EditData" :start-data="StartData" @save-file="SaveToFile" @load-file="LoadFromFile" @load-live="loadData"></Toolbar>
  </div>
</template>

<script>
import VehicleSelector from "@/components/VehicleSelector";
import PackageEditor from "@/components/PackageEditor";
import Toolbar from "@/components/Toolbar";
import axios from "axios";
import { saveAs } from 'file-saver';
export default {
  name: 'Editor',
  components: {
    Toolbar,
    PackageEditor,
    VehicleSelector
  },
  data() {
    return {
      'LiveData': false,
      'oLiveData': false,
      'Selection': false,
      'EditData': false,
      'StartData': false,
      'EditName': ''
    }
  },
  methods: {
    loadData: function () {
      let t = this;
      axios.get('https://cdn.vautogroup.ca/file/vauto-cdn/data/kia/service_packages.json')
          .then((data) => {
                t.LiveData = structuredClone(data.data)
                t.oLiveData = structuredClone(data.data)
                if(t.Selection) { t.onSelectVehicle(t.Selection) }
          }
          ).catch(function (error) {
        if (error.response) {
          console.log(error.response.data);
          console.log(error.response.status);
          console.log(error.response.headers);
        } else if (error.request) {
          console.log(error.request);
        } else {
          console.log('Error', error.message);
        }
      })
    },
    onSelectVehicle: function (vehicle) {
      this.Selection = vehicle;
      if(this.Selection) {
        if(!vehicle?.sub) {
          this.EditData = this.LiveData[vehicle.year][vehicle.model]
          this.StartData = this.oLiveData[vehicle.year][vehicle.model]
          this.EditName = vehicle.year+' KIA '+vehicle.model
        } else {
          this.EditData = this.LiveData[vehicle.year][vehicle.model][vehicle.sub]
          this.StartData = this.oLiveData[vehicle.year][vehicle.model][vehicle.sub]
          this.EditName = vehicle.year+' KIA '+vehicle.model+' '+vehicle.sub
        }
      }
    },
    SaveToFile: function () {
      let blob = new Blob([JSON.stringify(this.LiveData)], { type: 'text/json;charset=utf-8;'});
      this.saveAs(blob, 'service_packages.json')
      alert('Send file to it@vautogroup.ca to have published. You can \'Load from File\' to continue editing.')
    },
    LoadFromFile: function (data) {
      this.LiveData = structuredClone(data)
      this.oLiveData = structuredClone(data)
      this.onSelectVehicle(this.Selection);
    }
  },
  mounted() {
    this.loadData();
  },
  created() {
    this.saveAs = saveAs
  }
}
</script>

