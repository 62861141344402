<template>
  <div>
    <div class="text-3xl text-white/70 text-center mb-4 underline">{{name}}</div>
    <div class="editor">
      <div class="edit-card" v-for="(v,i) in editData" :key="i" :class="{'edited': !isEqual(editData[i],startData[i])}">
        <div>
          <label :class="{'edited': !isEqual(v.Title,startData[i].Title)}">Title:</label>
          <input v-model="v.Title" type="text"/>
        </div>
        <div>
          <label :class="{'edited': !isEqual(v.Description,startData[i].Description)}">Description:</label>
          <input v-model="v.Description" type="text"/>
        </div>
        <div>
          <label :class="{'edited': !isEqual(floor(v.Labour,2),floor(startData[i].Labour,2))}">Labour:</label>
          <input v-model="v.Labour" type="number" step="0.01" @change="v.Total = floor(v.Labour + v.Parts + v.Environmental,2)"/>
        </div>
        <div>
          <label :class="{'edited': !isEqual(floor(v.Parts,2),floor(startData[i].Parts,2))}">Parts:</label>
          <input v-model="v.Parts" type="number" step="0.01" @change="v.Total = floor(v.Labour + v.Parts + v.Environmental,2)"/>
        </div>
        <div>
          <label :class="{'edited': !isEqual(floor(v.Environmental,2),floor(startData[i].Environmental,2))}">Environmental:</label>
          <input v-model="v.Environmental" type="number" step="0.01" pattern="^\d+(\.)\d{2}$" @change="v.Total = floor(v.Labour + v.Parts + v.Environmental,2)"/>
        </div>
        <div>
          <label>Total:</label>
          <input disabled :value="floor(v.Labour + v.Parts + v.Environmental,2)"/>
        </div>
      </div>
      <div v-if="!editData">
        <p class="text-4xl text-white/70">Please Select A Vehicle To Start Editing</p>
      </div>
    </div>
  </div>
</template>

<script>
import {isEqual, floor} from "lodash";
export default {
  name: "PackageEditor",
  props: [
      'editData',
      'startData',
      'name'
  ],
  data() {
    return {
      isEqual: isEqual,
      floor: floor
    }
  }
}
</script>
